<template>
    

    <div>
        <div>
            <div class="grid">
                <div class="col-8">
                    <div class="grid">
                        <div class="col-12 h-full w-full   gaurav_landing card">
                           <img class="snimg" src="https://speednetworkingstorage.blob.core.windows.net/staticcontent/event_create.png" />
                           <!-- <lottie-player
                                autoplay
                                loop
                                mode="normal"
                                speed="2.5"
                                src="https://assets5.lottiefiles.com/private_files/lf30_qxakfuim.json"
                                >
                            </lottie-player> -->
                        </div>                        
                        <div class="col-12  card">
                            <div class="grid">
                                <!-- <div class="col-2">
                                    <div style="text-align: left;" class="text-xl ">Testinomials</div>
                                    <br/>
                                </div>  -->
                                <div class="col-12">
                                    <!-- <transition name="slide-fade">
                                        <h1 v-if="show" key="IF">I'm IF</h1>
                                        <h1 v-else key="ELSE">now it's ELSE</h1>
                                    </transition> -->
                                    <Transition mode="out-in" >
                                        <blockquote v-if="show" style="padding:0;"><h3  key="IF" style="text-align: left;" class="text-xl text-pink-500">{{current_message}}</h3></blockquote>
                                        <blockquote v-else style="padding:0;"><h3   key="ELSE" style="text-align: left;" class="text-xl text-pink-500">{{current_message}}</h3></blockquote>
                                    </Transition>
                                    
                                </div>             
                            </div>
                                                            
                        </div>
                    </div>
                        
                </div>
                <div class="col-4">
                    <div class="grid">
                        <div class="col-6  mt-8 mb-2  ml-6">
                            <div class="mb-5 text-xl" style="margin-left:10px;color:orangered">
                                Events Completed
                            </div>     
                            
                        </div>
                        <div class="col-4  mt-8 mb-2">
                            <div class="mb-5 text-xl" style="color:orangered">
                                <span v-if="count.ev==-1">Loading..</span><span v-if="count.ev>-1">{{ count.ev }}</span>
                            </div>                            
                        </div>
                        <div class="col-6  mt-2 mb-8  ml-6">
                            <div class="mb-5 text-xl" style="margin-left:10px;color:orangered">
                                SpeedConnects Made
                            </div>                            
                        </div>
                        <div class="col-4  mt-2 mb-8  ml-9">
                            <div class="mb-5 text-xl" style="color:orangered">
                                <span v-if="count.ev==-1">Loading..</span><span v-if="count.ev>-1">{{ count.sn }}</span>
                            </div>
                        </div>
                        <div class="col-12 h-full w-full m-0  px-4">
                            <div class="mb-5" style="text-align:center;">                        
                                <Button label="Create Event" style="width:80%" class="p-button-raised p-button-rounded  p-3 text-xl" @click="beforeCreate"></button>
                            </div>                                             
                        </div>
                        <div class="col-12 h-full w-full m-0  px-4">
                            <div class="mb-5" style="text-align:center;">                        
                                <Button label="Manage Events" style="width:80%" class="p-button-raised p-button-rounded  p-3 text-xl" @click="manageEvent"></button>
                            </div>                                             
                        </div>
                    </div>
                </div>
               
            </div>
        </div>
    </div>
</template>

<script>
// import "@lottiefiles/lottie-player";
import SNService from "../service/SNService";

export default {
	data() {
		return {
                messages:[
                    "It is really nice to know other colleagues that you didn’t get opportunity to meet earlier",
                    "Very nice initiative. Good to know new people​",
                    "It was very nice to meet people on the team. Good idea!​",
                    "The event worked very well. The length of event was good.​",
                    "I had a great time at this event! I got to meet 2 new people today and learned a lot about them​",
                    "This was a great event to meet members of the team that I normally wouldn't interact with​",
                    "I thought it was energizing and upbeat​","It was great talking to some people I have not met before.​",
                    "Was nice chatting with new people",
                    "I actually liked it better than being in a big meeting​",
                    "I found it very useful tool to build network within organization.​",
                    "This is a great way to connect with people on our team and learn more about each other.  It’s fun too!"
                ],            
                show:true,
                message_count :0,
                count:{"sn":-1,"ev":-1},
                current_message:"This is a great way to connect with people on our team and learn more about each other.  It’s fun too!"
			
		}
	},
    created(){
        this.srateService = new SNService();
    },
	async mounted() {
         this.myTimeout = setInterval(this.shuffle,2000);
         let response  = await this.srateService.getAnalytics();
         this.count = response['data'];
         console.log(this.count);
    },
	methods: {		
		manageEvent() {
            clearInterval(this.myTimeout);
            this.$router.push('/sn');

        },
        beforeCreate(){
            clearInterval(this.myTimeout);
            this.$router.push('/before/create');
        },
        shuffle(){
            if(!this.show){
                this.current_message=this.messages[this.message_count]
                this.show=!this.show;            
            }else{
                this.show=!this.show;  
                this.message_count = this.message_count == (this.messages.length -1)?0:(this.message_count+1)
            }
            
        }
		
	}
}
</script>

<style lang="scss" scoped>

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
.gaurav_landing::before {
    display: block;
    content: "";
    height: 800px;
    position: absolute;
    bottom: 1px;
    right: 5px;
    border-bottom: 5px solid orangered;
    border-right: 5px solid red;
}
.snimg {
    //width: 80%; /* or any custom size */
    //height: 450px; 
    //width:790px;
    //object-fit: contain;
    width:60%;
    height:60%;
    object-fit:cover;
}


blockquote {
    border:none;
    font-family:Georgia, "Times New Roman", Times, serif;
    quotes: "\201C""\201D""\2018""\2019";
}

blockquote h3 {
font-size:10px;
}

blockquote h3:before { 
content: open-quote;
font-weight: bold;
font-size:30px;
color:#889c0b;
} 
blockquote h3:after { 
content: close-quote;
font-weight: bold;
font-size:30px;
color:#889c0b;
  
}
</style>
