import { createRouter, createWebHashHistory } from 'vue-router';
import Landing from './components/landing.vue';
import store from '@/store';

const routes = [
    {
        path: '/',
        name: 'ahlanding',
        meta:{authRequired: true },
        component: Landing,
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        meta:{authRequired: true },
        component: () => import('./components/Dashboard.vue'),
    },
    {
        path: '/srate',
        name: 'srate',
        meta:{authRequired: true },
        component: () => import('./components/srate/SrateMaster.vue'),
    },
    {
        path: '/smres/:sysId/:eventId',
        name: 'smres',
        meta:{authRequired: true },
        component: () => import('./components/sm/smresponses.vue'),
    },
    {
        path: '/cre/:cycle',
        name: 'cre',
        component: () => import('./components/cre/calculation.vue'),
    },
    {
        path: '/smres/onetone/:id/:eventId',
        name: 'smres22',
        meta:{authRequired: true },
        component: () => import('./components/sm/smonetooneresponse.vue'),
    },
    {
        path: '/eventChildDetails',
        name: 'eventChildDetails',
        meta:{authRequired: true },
        component: () => import('./pages/eventList.vue'),
    },
    {
        path: '/ahmaster',
        name: 'ahmaster',
        meta:{authRequired: true },
        component: () => import('./components/applied_hiding/AHMaster.vue'),
    },
    {
        path: '/ahmaster/process',
        name: 'ahmaster_process',
        meta:{authRequired: true },
        component: () => import('./components/applied_hiding/processed/processapplication.vue'),
    },
    {
        path: '/srate/settings',
        name: 'sratesettings',
        meta:{authRequired: true },
        component: () => import('./components/srate/SrateSetting.vue'),
    },
    {
        path: '/formlayout',
        name: 'formlayout',
        component: () => import('./components/FormLayoutDemo.vue')
    },
    {
        path: '/input',
        name: 'input',
        component: () => import('./components/InputDemo.vue')
    },
    {
        path: '/floatlabel',
        name: 'floatlabel',
        component: () => import('./components/FloatLabelDemo.vue')
    },
    {
        path: '/invalidstate',
        name: 'invalidstate',
        component: () => import('./components/InvalidStateDemo.vue')
    },
    {
        path: '/button',
        name: 'button',
        component: () => import('./components/ButtonDemo.vue')
    },
    {
        path: '/table',
        name: 'table',
        component: () => import('./components/TableDemo.vue')
    },
    {
        path: '/list',
        name: 'list',
        component: () => import('./components/ListDemo.vue')
    },
    {
        path: '/tree',
        name: 'tree',
        component: () => import('./components/TreeDemo.vue')
    },
    {
        path: '/panel',
        name: 'panel',
        component: () => import('./components/PanelsDemo.vue')
    },
    {
        path: '/overlay',
        name: 'overlay',
        component: () => import('./components/OverlayDemo.vue')
    },
    {
        path: '/media',
        name: 'media',
        component: () => import('./components/MediaDemo.vue')
    },
    {
        path: '/menu',
        component: () => import('./components/MenuDemo.vue'),
        children: [
            {
                path: '',
                component: () => import('./components/menu/PersonalDemo.vue')
            },
            {
                path: '/menu/seat',
                component: () => import('./components/menu/SeatDemo.vue')
            },
            {
                path: '/menu/payment',
                component: () => import('./components/menu/PaymentDemo.vue')
            },
            {
                path: '/menu/confirmation',
                component: () => import('./components/menu/ConfirmationDemo.vue')
            },
        ],
    },
    {
        path: '/messages',
        name: 'messages',
        component: () => import('./components/MessagesDemo.vue')
    },
    {
        path: '/file',
        name: 'file',
        component: () => import('./components/FileDemo.vue')
    },
    {
        path: '/chart',
        name: 'chart',
        component: () => import('./components/ChartDemo.vue')
    },
    {
        path: '/misc',
        name: 'misc',
        component: () => import('./components/MiscDemo.vue')
    },
    {
        path: '/crud',
        name: 'crud',
        component: () => import('./pages/CrudDemo.vue')
    },
    {
        path: '/sn',
        name: 'snet',
        meta:{authRequired: true },
        component: () => import('./pages/SpeedNetworkingCrud.vue'),
        props: true
    },
    {
        path: '/before/create',
        name: 'bcreate',
        meta:{authRequired: true },
        component: () => import('./components/speednetworking/BeforeCreate.vue'),
        props: true
    },
    {
        path: '/roster/event/:id/:mid/:creator_allowed',
        name: 'revent',
        meta:{authRequired: true },
        component: () => import('./components/speednetworking/roster/RosterEvent.vue'),
        props: true
    },
    {
        path: '/roster/approved/:id',
        name: 'reventapproved',
        meta:{authRequired: true },
        component: () => import('./components/speednetworking/roster/RosterApproved.vue'),
        props: true
    },
    {
        path: '/create/event',
        name: 'create',
        meta:{authRequired: true },
        component: () => import('./components/speednetworking/create/CreateEvent.vue'),
        props: true
    },
    {
        path: '/sneventlist',
        name: 'sneventlist',
        meta:{authRequired: true },
        component: () => import('./pages/eventList.vue')
    },
    {
        path: '/snt',
        name: 'snettemplate',
        meta:{authRequired: true },
        component: () => import('./pages/SpeedNetworkingTemplates.vue')
    },
    {
        path: '/timeline',
        name: 'timeline',
        component: () => import('./pages/TimelineDemo.vue')
    },
    {
        path: '/empty',
        name: 'empty',
        component: () => import('./components/EmptyPage.vue')
    },
    {
        path: '/documentation',
        name: 'documentation',
        component: () => import('./components/Documentation.vue')
    },
    {
        path: '/blocks',
        name: 'blocks',
        component: () => import('./components/BlocksDemo.vue')
    },
    {
        path: '/icons',
        name: 'icons',
        component: () => import('./components/IconsDemo.vue')
    },
    {
        path: '/landing',
        name: 'landing',
        meta:{authRequired: true },
        component: () => import('./pages/LandingDemo.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login.vue')
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error.vue')
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('./pages/NotFound.vue')
    },
    {
        path: '/access',
        name: 'access',
        component: () => import('./pages/Access.vue')
    },
    {
		path: '/formulation/:module/:account?',
		name: 'formulation',
		component: () => import('./pages/NotFound.vue')
	},
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach(function(to, from, next) {
    let isAuthenticate=store.getters.isAuthenticate;
    // Don't know how azure redirect handle
    // if(to.name=="ahlanding" && from.name=="ahlanding"){
    //     window.scrollTo(0, 0);
    //     next();
    //     return;
    // }
    // if(isAuthenticate && to.name=="login"){
    //     console.log("11111");
    //     next({ name: 'ahlanding' });
    //     return;
    // }
    //console.log(store.getters.getAccessToken);
    if(!isAuthenticate && to.meta.authRequired){
        next({ name: 'login'});
    }else{        
        window.scrollTo(0, 0);
        next();
    }
    
    
    
   
});

export default router;
