import { createStore } from 'vuex';

import { PublicClientApplication } from '@azure/msal-browser';

const store = createStore({
  state() {
    return {
      msalConfig: {
        auth: {
          clientId: '50887661-3942-49c3-9c4f-1426bad4de74',
          authority:
            'https://login.microsoftonline.com/68a7ff20-812f-4738-90ea-0b1051846f74',
          scopes:['api://60cf255d-c1bf-4e99-bc65-17ec6bcb28b6/snusers'],  
          redirectUri: window.location.origin+"/notfound",
        },
        cache: {
          cacheLocation: 'localStorage',
        },
      },
      accessToken: '',
      user:undefined,
    };
  },
  mutations :{
    setAccessToken(state, token){
      state.accessToken = token;
    },
    setUser(state, username) {
      state.user = username;
    },
    logout(state, user) {
      state.user = user;
    },
  },
  getters: {
    isAuthenticate (state) {
      const msalInstance=new PublicClientApplication(state.msalConfig)
      let accounts=msalInstance.getAllAccounts();
      if (accounts.length == 0) {
        return false;
      }
      return true;
    },
    getUser(state){
      const msalInstance=new PublicClientApplication(state.msalConfig)
      let accounts=msalInstance.getAllAccounts();
      if (accounts.length == 0) {
        return "anonymous";
      }
      return accounts[0];
    },
    async getAccessToken(state){
      // if(localStorage.getItem("token")){
      //   return localStorage.getItem("token");
      // }
      
      const msalInstance=new PublicClientApplication(state.msalConfig);
      let tokenRequest={
        scopes:['api://60cf255d-c1bf-4e99-bc65-17ec6bcb28b6/snusers'],  
        account: msalInstance.getAllAccounts()[0],
        redirectUri: window.location.origin+"/notfound",
      };
      return await msalInstance.acquireTokenSilent(tokenRequest)
            .then(response => {
                localStorage.setItem("token",response.accessToken);
                return response.accessToken;
            }).catch(async (error) => {
              //Acquire token silent failure, and send an interactive request
                msalInstance
                  .acquireTokenPopup(tokenRequest)
                  .then(function (accessTokenResponse) {
                    // Call your API with token
                    localStorage.setItem("token",accessTokenResponse.accessToken);
                    return accessTokenResponse.accessToken;
                  })
                  .catch(function (error) {
                    // Acquire token interactive failure
                    console.log(error);
                  });
              
              console.log(error);
              console.log(error);              
            });
            
        },
        async getGraphAccessToken(state){
          // if(localStorage.getItem("token")){
          //   return localStorage.getItem("token");
          // }
          
          const msalInstance=new PublicClientApplication(state.msalConfig);
          let tokenRequest={
            scopes:["Calendars.ReadWrite","Mail.Read","Mail.Send","User.Read","User.ReadBasic.All","Directory.Read.All"],  
            account: msalInstance.getAllAccounts()[0],
            redirectUri: window.location.origin+"/notfound",
          };
          return await msalInstance.acquireTokenSilent(tokenRequest)
                .then(response => {
                    localStorage.setItem("token",response.accessToken);
                    return response.accessToken;
                }).catch(async (error) => {
                  console.log(error);              
                });
                
            }
  },
  
});

export default store;