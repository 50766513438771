import axios from 'axios'
import store from '@/store';

export default class BaseService {
  

    handleError(error){
        let message={}
        if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            message={"message":error.response.data,code:error.response.status}
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
            message={"message":"Either Server Not responding or No Network Connection",code:0}
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log(error.message);
            if(error!=undefined && error.message && (error.message.detail=="Token signature has expired")
              || error.message.detail=="Invalid token format"
            ){
              localStorage.removeItem("token");
              window.location.href="/";
            }
            console.log('Error', error.message);
            message={"message":error.message,code:1}
          }
         return  Promise.reject(message)
    }

    constructor(){
        let classref=this;
        axios.interceptors.request.use(async config => {
            // Do something before request is sent
            if(config.url.includes("/api")){
              //let value= await classref.getToken(store.getters.getAccessToken);
              let value = await store.getters.getAccessToken;
              let gvalue= await store.getters.getGraphAccessToken;
              let timeZone= Intl.DateTimeFormat().resolvedOptions().timeZone;
              config.headers={"Authorization": "Bearer "+value,'tz':timeZone, "GAuth":"Bearer "+gvalue}
              return config;
            }else{
              return config;
            }            
            
          }, function (error) {
            return classref.handleError(error);
          });
        
        // Add a response interceptor
        axios.interceptors.response.use(function (response) {
            return response;
          }, function (error) {
            console.log(error);
            return classref.handleError(error);
          });
    }

    getAxios(){
        return axios;
    }

	
}
