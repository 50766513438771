import BaseService from './BaseService';

export default class SNService extends BaseService{


    constructor(){
        super();
    }

	get(skip) {
		return this.getAxios().get('/api/net/events/'+skip)
    }

    async getAnalytics() {
		return this.getAxios().get('/api/analytics/count')
    }

    save(srate_record){       
        return this.getAxios().post('/api/net/events', srate_record)
    }

    delete(id){      
        return this.getAxios().delete('/api/net/events/'+id)
    }

    viewroster(sid,id){
        return this.getAxios().get('/api/event/roster/view/users/response/'+sid+'/'+id)
    }
    

    roster(id,eid,allowed){
        return this.getAxios().get('/api/event/roster/create/'+id+"/"+eid+"/"+allowed)
    }

    sendroster(id,eid,all_events){
        return this.getAxios().post('/api/event/roster/send/'+id+"/"+eid,all_events)
    }

    rosterApproved(id){       
        return this.getAxios().get('/api/net/items/'+id)
    }


    usersettings(){
        return this.getAxios().get('/api/net/mail/settings')
    }

    cancel_event(id){      
        return this.getAxios().delete('/api/net/event/cancel/'+id)
    }

    add_required_user(email,id){
        return this.getAxios().post('/api/net/events/additional/user?email='+email+'&id='+id)
    }
    
}
