<template>

    <div style="height: 100vh;background-color: white;">
        <div class="grid">
        <div class="col-12">
            <div class="landingheader">                
                <div class="grid">
                    <div class="col-3">
                        <img id="logo" src="images/chem-icon-white.svg" alt="brand icon" height="35" />
                    </div>                    
                </div>  
            </div>
        </div>
        <div class="col-5 surface-0">
            <div class="grid lg:p-0">                    
                <div class="text-center col-12">
                    <div class="grid">
                        <div class="col-12 h-full w-full mb-8  px-4 ml-9">
                            &nbsp;
                        </div>
                        <div class="col-12 h-full w-full m-0  px-4">
                            <div class="mb-5">                        
                                <div class="text-1000 text-7xl w-full font-large mb-3">Grow your Network!</div>                        
                            </div>
                            <div class="mb-8"> 
                                <span class="text-800 text-2xl font-large">&nbsp;&nbsp;Let's connect and get to know each other</span>
                            </div>

                            <div class="mt-4">            
                                <Button label="Sign In" style="width:60%" class="p-button-raised p-button-rounded  p-3 text-xl" @click="SignIn"></button>

                            </div>
                        </div>
                    </div>                        
                </div>
            </div>
        </div>        
        <div class="col-7 surface-0">
            <div class="col-8 h-full w-full   gaurav_landing ">
                           <img style="width: inherit;"  src="https://speednetworkingstorage.blob.core.windows.net/staticcontent/event_create.png" />
                           <!-- <lottie-player
                                autoplay
                                loop
                                mode="normal"
                                speed="2.5"
                                src="https://assets5.lottiefiles.com/private_files/lf30_qxakfuim.json"
                                >
                            </lottie-player> -->
                        </div>    
            <!-- <div class="grid lg:p-0" >
                <div class="text-center col-12">
                        <div class="grid">
                            <div class="col-12  h-full w-full mb-8  px-4 ml-9"> 
                                &nbsp;
                            </div> 
                            <div class="col-12 h-full w-full m-0  px-4 mb-8">                        
                                <div style="font-weight:500;color: darkorange;font-size:5rem" class="mb-1">SpeedNetworking</div>                        
                            </div>
                            
                        </div>
                        <div class="grid text-center" >   
                            <div class="col-2 mb-0 text-center"> 
                                &nbsp;
                            </div> 
                            <div class="col-6 mb-0 center"> 
                                <div>
                                    <img id="logo" src="images/MicrosoftTeams-image.png" alt="brand icon" height="55" />
                                </div>
                            </div>
                            <div class="col-4 mb-0 text-center"> 
                                &nbsp;
                            </div> 
                        </div>
                    
                    </div>
                   </div>  -->
            </div>
    </div>
    </div>

    
       
    <!--<div class="landing-footer grid">
        <div class="title">Ti-Pure Formulator</div>
        <div class="col-3"></div>
        <div class="col-6 app-title"></div>
        <div class="col-3"></div>
    </div> -->

</template>

<script>
import { PublicClientApplication } from '@azure/msal-browser';
import { mapMutations } from "vuex";

export default {
    data() {
        return {
            email: '',
            password: '',
            checked: false,
            items: [1,2,3],
            i:1
        }
    },
   methods: {
		...mapMutations(["setAccessToken","setUser", "setToken"]),
		async SignIn() {
            clearInterval(this.myTimeout);
            const loginRequest = {
                scopes: ["openid", "profile", "User.Read","Calendars.ReadWrite"]
            };
            await this.$msalInstance
                .loginPopup(loginRequest)
                .then(() => {
                    const myAccounts = this.$msalInstance.getAllAccounts();
                    this.account = myAccounts[0];                    
                    this.$emitter.emit('login', this.account);
					this.setUser(this.account);                    
                    window.location.href = "/"
                })
                .catch(error => {
                    console.error(`error during authentication: ${error}`);
                });
        },
        shuffle() {
            console.log(this.i%2==0);
            if(this.i%2==0){
                this.items = [1,2,3]
            }else{
                this.items = [2,3,1]
            }
            this.i=this.i+1;
            
        },
        clearTimeout1(){
            clearInterval(this.myTimeout);
        }
    },
	computed: {
		darkTheme() {
			return this.$appState.darkTheme;
		}
	},
	async created() {
    this.$msalInstance = new PublicClientApplication(
      this.$store.state.msalConfig,
    );

  },
  mounted() {
    const accounts = this.$msalInstance.getAllAccounts();
    if (accounts.length == 0) {
      return;
    }
    this.account = accounts[0];
	console.log(this.account);
    this.$emitter.emit('login', this.account);
    this.shuffle();
    this.myTimeout = setInterval(this.shuffle,2000);
    
	
  },
}
</script>

<style lang="scss">

#logo {
    float: left;
}
#brand {
    float: right;
}

.landing-logo-container {
    widows: 100%;
}

.landingheader {
    background-color: white;
    z-index:8888;
    -webkit-box-shadow: 0px 4px 10px -2px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 4px 10px -2px rgba(0, 0, 0, 0.3);
    box-shadow:  0 4px 10px -2px rgba(0, 0, 0, 0.3);
    width: 100%;

}

.beforeheader{
        background-image:linear-gradient(to right,#e53e30,#f28b00);
        background-image: -webkit-gradient(linear,left top,right top,from(#e53e30),to(#f28b00));

}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
}
.gaurav:after {
    display: block;
    content: "";
    height: "200";
    position: absolute;
    bottom: 5px;
    right: 5px;
    border-bottom: 5px solid orangered;
    border-right: 5px solid red;
}

.landing-footer {
    background-color: #33a8c9;
    
}
.landingheader,
.landing-footer {
    padding: 0rem;
    margin: 0px;

    .col-6,
    .col-3 {
        padding: 1.2rem;
    }
}

</style>