<template>
	<div class="layout-topbar">
		<router-link to="/" class="layout-topbar-logo">
            <img id="logo" src="images/chem-icon-white.svg" alt="brand icon" height="35" />

		</router-link>
		<!-- <button  class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle">
			<i class="pi pi-bars"></i>
		</button> -->

		<button class="p-link layout-topbar-menu-button layout-topbar-button"
			v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', 
			leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true}">
			<i class="pi pi-ellipsis-v" ></i>
		</button>
		<ul class="layout-topbar-menu hidden lg:flex origin-top">
			<!-- <li>
				<button class="p-link layout-topbar-button">
					<i class="pi pi-calendar"></i>
					<span>Events</span>
				</button>
			</li>
			<li>
				<button class="p-link layout-topbar-button">
					<i class="pi pi-cog"></i>
					<span>Settings</span>
				</button>
			</li> -->
			
			<li>
				<div  class="p-link layout-topbar-text" 
					v-if="account">{{ account.name }}</div>
			</li>
			<li>				
				<Button style="background: none;" class="p-link layout-topbar-button" @click="SignOut" target="_blank" rel="noopener noreferrer">
					<i class="pi pi-sign-out"></i>
					
				</Button>
			</li>
		</ul>
	</div>
</template>

<script>
import { PublicClientApplication } from '@azure/msal-browser';
import { mapMutations } from "vuex";

export default {
	data() {
        return {
            account: undefined,
		}
	},
    methods: {
		...mapMutations(["setUser", "setToken"]),
        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },
		onTopbarMenuToggle(event) {
            this.$emit('topbar-menu-toggle', event);
        },
		topbarImage() {
			return this.$appState.darkTheme ? 'images/logo-white.svg' : 'images/logo-dark.svg';
		},
		
        async SignOut() {
            await this.$msalInstance
                .logout({})
                .then(() => {
                this.$emitter.emit('logout', 'logging out');
                })
                .catch(error => {
                console.error(error);
                });
        },
    },
	computed: {
		darkTheme() {
			return this.$appState.darkTheme;
		}
	},
	async created() {
    this.$msalInstance = new PublicClientApplication(
      this.$store.state.msalConfig,
    );
  },
  mounted() {
    const accounts = this.$msalInstance.getAllAccounts();
    if (accounts.length == 0) {
      return;
    }
    this.account = accounts[0];
	
  },
}
</script>

<style scoped>
.layout-topbar{
		background-color:white;
		position: fixed;
		width: 100%;
}
.layout-topbar .layout-topbar-text{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: black;
	font-weight:600;
    border-radius: 50%;
    width: 10rem;
    height: 3rem;
    cursor: pointer;
    transition: background-color 0.2s;
}
</style>